<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col :cols="12">
        <p
            class="
                        text-h5

                        text-uppercase
                        d-flex
                        align-center
                        lighten__font
                    "
        >图片管理
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2 float-end"
              small
              color="info"
              @click="add"
          >
            添加图片
          </v-btn>
        </p>
      </v-col>
      <v-col :cols="12">
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:item.filepath="{item}">
            <v-img :src="item.filepath+randomPath" :aspect-ratio="5/2" width="300" class="my-2"></v-img>
          </template>
          <template v-slot:item.action="{item}">
            <v-btn small color="primary"  class="mr-1" @click="edit(item)">修改</v-btn>
            <v-btn small color="error"  class="mr-1" @click="del(item.id)">删除</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>{{ id ? '修改图片' : '添加图片' }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field outlined dense v-model="item.key" label="类型">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-file-input outlined dense v-model="file" label="文件">

              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="update">{{ id ? '确认修改' : '确认添加'}}</v-btn>
          <v-btn small text @click="dialog=false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deldialog" width="800">
      <v-card>
        <v-card-title>
          确认删除该文件？
        </v-card-title>
        <v-card-text>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text @click="deleteType">确认</v-btn>
          <v-btn small text @click="deldialog=false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {getImg,delImg,editImg,createImg} from "../../api/image";
import {mapState} from "vuex";
export default {
  name: "index",
  data(){
    return{
      desserts:[],
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: '名称',
          value: 'key'
        },
        // {
        //   text: 'value',
        //   value: 'value'
        // },
        {
          text: '文件',
          value: 'filepath'
        },
        {
          text:'操作',
          value: 'action'
        }
      ],
      item: {},
      id: '',
      dialog:false,
      deldialog:false,
      file:null,
      randomPath:'?t='+Math.random(),
    }
  },
  mounted() {
    this.getImages()
  },
  computed:{
    ...mapState(['cos']),
  },
  methods:{
    getImages(){
      getImg().then(res => {
        const {results} = res
        this.desserts = results
        console.log(this.desserts)
      })
    },
    add(){
      this.item={}
      this.id=''
      this.dialog=true
    },
    edit(item) {
      this.id=item.id
      this.item=JSON.parse(JSON.stringify(item))
      this.dialog=true
    },
    del(id) {
      this.id = id
      this.deldialog=true
    },

    upload(file,type) {
      if (file) {
        return new Promise(resolve => {
          const COS = require('cos-js-sdk-v5')
          const cos = new COS({
            SecretId: this.cos.tmpSecretId, // 身份识别ID
            SecretKey: this.cos.tmpSecretKey,// 身份秘钥
            XCosSecurityToken: this.cos.sessionToken
          })
          const generate_random = (min, max) => Math.floor(((max - min) * Math.random()) + min)
          cos.putObject({
            Bucket: 'jkexpress-1307254482', /* 存储桶 */
            Region: 'ap-shanghai', /* 存储桶所在地域，必须字段 */
            Key: `/${type}/${this.getTime((new Date()).getTime() / 1000, "yyyy-MM-dd")}/${(new Date()).getTime()}/${generate_random(1000,9999)}`, /* 文件名 */
            StorageClass: 'STANDARD', // 上传模式, 标准模式
            Body: file, // 上传文件对象
            onProgress: (progressData) => { // 上传进度
              console.log(JSON.stringify(progressData))
            }
          }, (err, data) => {
            console.log(err || data)
            // 上传成功之后
            if (data?.statusCode === 200) {
              // let path=`https://${data.Location}`
              const {Location} = data
              resolve(Location)
            }
          })
        })
      }
    },

    getTime (time, fmt) {
      if (!time) return ''

      const date = new Date(time * 1000)

      const o = {
        'M+': date.getMonth() + 1,               // 月份
        'd+': date.getDate(),                    // 日
        'h+': date.getHours(),                   // 小时
        'm+': date.getMinutes(),                 // 分
        's+': date.getSeconds(),                 // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds()             // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    },

    async update(){
      if (this.file&&this.item?.key){
        console.log(this.file)
        this.item.filepath='https://'+await this.upload(this.file,this.item.key)
      }
      console.log(this.item)
      if (this.id){
        try {
          await editImg(this.item)
          this.$snackbar({
            content: '修改成功',
            color: 'success',
            showClose: true,
          })
        }catch (e) {
          console.log(e)
        }finally {
          this.dialog=false
          this.getImages()
          this.file=null
        }

      }else{
        try {
          await createImg(this.item)
          this.$snackbar({
            content: '添加成功',
            color: 'success',
            showClose: true,
          })
        }catch (e) {
          console.log(e)
        }finally {
          this.dialog=false
          this.getImages()
          this.file=null
        }
      }
    },
    async deleteType(){
      try {
        await delImg(this.id)
        this.$snackbar({
          content: '删除成功',
          color: 'success',
          showClose: true,
        })
      }catch (e){
        console.log(e)
      }finally {
        this.deldialog=false
        this.getImages()
      }

    }
  }
}
</script>

<style scoped>

</style>